import { UUID } from './UUID';

export enum AccountRole {
  ADMIN = 'ADMIN',
  CENTRE_MANAGER = 'CENTRE_MANAGER',
  MARKER = 'MARKER'
}

interface Account {
  readonly id: UUID<Account>,
  readonly name: string,
  readonly email: string,
  readonly roles: AccountRole[]
}

namespace Account {
  export function firstName(account: Account) {
    return account.name.split(' ')[0]
  }

  export function hasRole(account: Account, role: AccountRole) {
    return account.roles.find(r => r === role) !== undefined;
  }
}

export default Account;
