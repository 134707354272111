import * as React from 'react';
import { useEffect, useState } from 'react';
import Account from '../../domain/Account';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMe, postLogout } from '../../gateway/accounts';
import { ROUTE_DASHBOARD, ROUTE_LOGIN, ROUTE_SETUP } from '../../UkloRoutes';

interface AccountContext {
  readonly account?: Account,
  readonly invalidate: () => void,
  readonly logout: () => void,
  readonly onLogin: (account: Account) => void,
  readonly isLoggedIn: boolean
}

const AccountContext = React.createContext<AccountContext>({ logout: () => {}, onLogin: () => {}, invalidate: () => {}, isLoggedIn: false });

export const useAccount = () => {
  return React.useContext(AccountContext);
};

export const AccountProvider = ({ children }: { children: React.ReactNode }) => {
  const [account, setAccount] = useState<Account | undefined>(undefined);
  const location = useLocation();
  const navigate = useNavigate();

  const homepage = (account: Account) => account.roles.length === 0 ? ROUTE_SETUP : ROUTE_DASHBOARD;

  const invalidate = () => {
    getMe()
      .then(account => {
        setAccount(account);
        if (location.pathname === '/') {
          navigate(homepage(account));
        }
      })
      .catch(() => {
        setAccount(undefined);
        navigate(ROUTE_LOGIN);
      });
  };

  const context = {
    account,
    invalidate,
    logout: () => {
      postLogout()
        .finally(() => {
          setAccount(undefined);
          navigate(ROUTE_LOGIN);
        })
    },
    onLogin: (account: Account) => {
      setAccount(account);
      navigate(homepage(account));
    },
    isLoggedIn: account != undefined,
  };

  useEffect(invalidate, []);

  return (
    <AccountContext.Provider value={context}>
      {children}
    </AccountContext.Provider>
  );
};
