import { DataGrid, GridActionsCellItem, GridColumns } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Student from '../../../domain/Student';
import Gender from '../../../domain/Gender';
import doNothing from '../../common/doNothing';
import { UUID } from '../../../domain/UUID';
import { Tooltip } from '@mui/material';
import Season, { Exam } from "../../../domain/Season";

interface Props {
  readonly season: Season,
  readonly rows: Student[],
  readonly displayRegistered?: boolean,
  readonly editable?: boolean,
  readonly onDeleteStudent?: (studentId: UUID<Student>) => void
}

export default function CentreStudentsTableDataGrid({ season, rows, displayRegistered, editable, onDeleteStudent }: Props) {
  const columns: GridColumns<Student> = [
    { headerName: 'Name', flex: 5, field: 'name', valueGetter: params => params.row.name },
    { headerName: 'Gender', flex: 2, field: 'gender', valueGetter: params => Gender.pretty(params.row.gender) },
    { headerName: 'Year', flex: 2, field: 'year', valueGetter: params => params.row.year == "" ? "Unknown" : params.row.year },
    { headerName: 'Exam', flex: 3, field: 'examId', valueGetter: params => Exam.name(season, Object.values(season.exams).find(e => e.examId === params.row.examId)) }
  ];

  if (displayRegistered) {
    columns.push({ headerName: 'Registered', sortable: false, field: 'registered', valueGetter: () => 'Yes' });
  }

  if (rows.some(student => student.createdByMarker)) {
    columns.splice(1, 0, {
      headerName: 'Added in marking',
      flex: 2,
      field: 'createdByMarker',
      headerAlign: 'left',
      align: 'left',
      valueGetter: ({ row }) => row.createdByMarker,
      renderCell: ({ row }) => row.createdByMarker ? 'Yes' : 'No'
    });
  }

  if (editable) {
    columns.push({
      field: 'actions',
      type: 'actions',
      headerName: 'Unregister',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          // @ts-ignore
          <GridActionsCellItem
            icon={<Tooltip title="Unregister"><CloseIcon /></Tooltip>}
            label="Unregister"
            onClick={() => {
              if (onDeleteStudent !== undefined) {
                onDeleteStudent(id as UUID<Student>)
              }
            }}
            color="error"
          />,
        ];
      },
    });
  }

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      getRowId={student => student.id}
      density="compact"
      autoHeight
      pageSize={rows.length}
      hideFooter
      disableColumnMenu
      disableColumnSelector
      disableSelectionOnClick
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        },
      }}
    />
  );
}
