import * as React from "react";
import { useEffect, useState } from "react";
import Centre from "../../../domain/Centre";
import { UUID } from "../../../domain/UUID";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import Student from "../../../domain/Student";
import { getStudents } from "../../../gateway/students";
import CentreStudentsTableDataGrid from "./CentreStudentsTableDataGrid";
import Season from "../../../domain/Season";

interface Props {
  readonly season: Season,
  readonly centreId: UUID<Centre>
}

export default function StudentResultsSection({ season, centreId }: Props) {
  const [students, setStudents] = useState<Student[] | undefined>(undefined);

  useEffect(() => {
    getStudents({ centreId, seasonId: season.id }).then(setStudents);
  }, [season.id, centreId]);

  if (students === undefined) {
    return <Typography>Loading</Typography>;
  }

  const noStudents = students.length === 0;

  return (
    <Card variant="outlined">
      <CardHeader
        title={`Students for ${season.year}`} sx={noStudents ? {} : { pb: 0 }}
        subheader={noStudents && "You didn't register any students this year. If this was an oversight, you can still upload Advanced exam documents and mark non-Advanced papers."}
      />
      {!noStudents && (
        <CardContent>
          <CentreStudentsTableDataGrid season={season} rows={students} />
        </CardContent>
      )}
    </Card>
  );
}
