import * as React from "react";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Season, { SeasonMetadata } from "../../domain/Season";
import { Button, Card, CardContent, CardHeader, Paper, Stack, Typography } from "@mui/material";
import ConfirmDeleteSeasonDialog from "./ConfirmDeleteSeasonDialog";
import { deleteSeason, putSeasonState } from "../../gateway/exams";
import DeleteIcon from "@mui/icons-material/Delete";
import NextIcon from "@mui/icons-material/Start";
import PreviousIcon from "@mui/icons-material/Undo";
import SeasonState from "../../domain/SeasonState";
import ExamDocumentTable from "../documents/ExamDocumentTable";
import exhaustiveGuard from "../common/exhaustiveGuard";
import { UUID } from "../../domain/UUID";
import SeasonStateProgressionDescription from "./SeasonStateProgressionDescription";
import { useCurrentSeason } from "./SeasonContext";

interface Props {
  readonly season: SeasonMetadata,
  readonly displaySeasonDescription: boolean,
  readonly onChange: () => void,
}

export default function SeasonCard({ season, displaySeasonDescription, onChange }: Props) {
  const { currentSeason } = useCurrentSeason();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleConfirmDelete = () => {
    deleteSeason(season.id)
      .then(() => setDeleteDialogOpen(false))
      .then(onChange);
  }

  const actionButtons = [];

  switch (season.state) {
    case SeasonState.UNPUBLISHED:
      actionButtons.push(
        <Button
          key="delete"
          onClick={() => setDeleteDialogOpen(true)}
          color="error"
          size="small"
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      );
      actionButtons.push(changeSeasonStateButton(SeasonState.REGISTRATION, "publish", "Publish", "success", <NextIcon />, season.id, onChange));
      break;

    case SeasonState.REGISTRATION:
      actionButtons.push(changeSeasonStateButton(SeasonState.UNPUBLISHED, "unpublish", "Unpublish", "warning", <PreviousIcon />, season.id, onChange));
      actionButtons.push(changeSeasonStateButton(SeasonState.ROUND_1_TEST_PERIOD, "start", "Start round 1", "success", <NextIcon />, season.id, onChange));
      break;

    case SeasonState.ROUND_1_TEST_PERIOD:
      actionButtons.push(changeSeasonStateButton(SeasonState.REGISTRATION, "revert", "Reopen registration", "warning", <PreviousIcon />, season.id, onChange));
      actionButtons.push(changeSeasonStateButton(SeasonState.ROUND_1_ADVANCED_MARKING, "mark", "Start marking", "success", <NextIcon />, season.id, onChange));
      break;

    case SeasonState.ROUND_1_ADVANCED_MARKING:
      actionButtons.push(changeSeasonStateButton(SeasonState.ROUND_1_TEST_PERIOD, "revert", "Reopen test period", "warning", <PreviousIcon />, season.id, onChange));
      actionButtons.push(changeSeasonStateButton(SeasonState.ROUND_1_ADVANCED_RESULTS_VERIFICATION, "close-advanced", "Close Advanced marking", "success", <NextIcon />, season.id, onChange));
      break;

    case SeasonState.ROUND_1_ADVANCED_RESULTS_VERIFICATION:
      actionButtons.push(changeSeasonStateButton(SeasonState.ROUND_1_ADVANCED_MARKING, "revert", "Reopen Advanced marking", "warning", <PreviousIcon />, season.id, onChange));
      actionButtons.push(changeSeasonStateButton(SeasonState.ROUND_1_ADVANCED_RESULTS_PUBLICATION, "publish-advanced", "Publish Advanced results", "success", <NextIcon />, season.id, onChange));
      break;

    case SeasonState.ROUND_1_ADVANCED_RESULTS_PUBLICATION:
      actionButtons.push(changeSeasonStateButton(SeasonState.ROUND_1_ADVANCED_RESULTS_VERIFICATION, "revert", "Unpublish Advanced results", "warning", <PreviousIcon />, season.id, onChange));
      actionButtons.push(changeSeasonStateButton(SeasonState.ROUND_1_RESULTS_PUBLICATION, "publish-other", "Publish non-Advanced results", "success", <NextIcon />, season.id, onChange));
      break;

    case SeasonState.ROUND_1_RESULTS_PUBLICATION:
      actionButtons.push(changeSeasonStateButton(SeasonState.ROUND_1_ADVANCED_RESULTS_PUBLICATION, "revert", "Unpublish non-Advanced results", "warning", <PreviousIcon />, season.id, onChange));
      actionButtons.push(changeSeasonStateButton(SeasonState.ROUND_1_MATERIALS_PUBLICATION, "publish-other", "Publish all exam materials", "success", <NextIcon />, season.id, onChange));
      break;

    case SeasonState.ROUND_1_MATERIALS_PUBLICATION:
      actionButtons.push(changeSeasonStateButton(SeasonState.ROUND_1_RESULTS_PUBLICATION, "revert", "Unpublish exam materials", "warning", <PreviousIcon />, season.id, onChange));
      actionButtons.push(changeSeasonStateButton(SeasonState.COMPLETE, "finalise", "Finalise exam season", "success", <NextIcon />, season.id, onChange));
      break;

    case SeasonState.COMPLETE:
      if (season.id === currentSeason?.id) {
        actionButtons.push(changeSeasonStateButton(SeasonState.ROUND_1_MATERIALS_PUBLICATION, "revert", "Reopen", "warning", <PreviousIcon />, season.id, onChange));
        break;
      } else {
        return (
          <Card variant="outlined">
            <CardHeader
              title={season.year}
              subheader={SeasonState.pretty(season.state)}
            />
          </Card>
        );
      }

    default:
      exhaustiveGuard(season.state);
  }

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          title={season.year}
          subheader={displaySeasonDescription ? null : SeasonState.pretty(season.state)}
          action={actionButtons.length === 0 ? null : (
            <Paper elevation={0} sx={{ px: 1, py: 0.5, mr: 0.5 }}>
              <Stack direction="row" spacing={1}>
                {actionButtons}
              </Stack>
            </Paper>
          )}
          sx={{backgroundColor: "primary.main", color: "primary.contrastText", p: 2}}
          subheaderTypographyProps={{variant: "subtitle2", color: "primary.contrastText"}}
        />
        <CardContent sx={{ p: 1 }}>
          {displaySeasonDescription && <SeasonStateProgressionDescription currentSeasonState={season.state} />}
          <Stack spacing={1} sx={{ pt: 1, px: 1 }}>
            <Typography variant="h6" sx={{ mb: 0 }}>Manage exam documents</Typography>
            <ExamDocumentTable seasonId={season.id} levels="all-editable" />
            <Link component={RouterLink} to={`/season/${season.id}`} underline="hover" sx={{ pt: 1 }}>
              Manage questions
            </Link>
          </Stack>
        </CardContent>
      </Card>
      <ConfirmDeleteSeasonDialog
        open={deleteDialogOpen}
        season={season}
        dismiss={() => setDeleteDialogOpen(false)}
        confirm={handleConfirmDelete}
      />
    </>
  );
}

function changeSeasonStateButton(target: SeasonState, key: string, label: string, color: "success" | "warning" | "error", icon: React.ReactNode, seasonId: UUID<Season>, onChange: () => void) {
  return (
    <Button
      key={key}
      onClick={() => putSeasonState(seasonId, target).then(onChange)}
      color={color}
      size="small"
      startIcon={icon}
    >
      {label}
    </Button>
  );
}
