import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import Layout from './components/layout/Layout';
import MarkingPage, { MarkingMode } from './components/marking/MarkingPage';
import LoginOrRegister from './components/account/LoginOrRegister';
import Dashboard from './components/dashboard/Dashboard';
import CentrePage from './components/centres/CentrePage';
import { UUID } from './domain/UUID';
import SeasonManagementPage from "./components/exams/SeasonManagementPage";
import SeasonsPage from "./components/exams/SeasonsPage";
import WelcomePage from "./components/dashboard/WelcomePage";
import Centre from "./domain/Centre";
import AdminPage from "./components/admin/AdminPage";
import MarkerAssignmentPage from "./components/assignment/MarkerAssignmentPage";

export const ROUTE_LOGIN = "/";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_SETUP = "/welcome";
export const ROUTE_CENTRE = (centreId: UUID<Centre>) => `/centre/${centreId}`;
export const ROUTE_MARKING = (centreId: UUID<Centre>, mode: MarkingMode) => `/marking/${mode}/${centreId}`;

const ID_PATH_PARAM = 'id';

type UUIDParam<T> = {
  [ID_PATH_PARAM]: UUID<T>
}

export function useUUIDParam<T>(): UUID<T> {
  const navigate = useNavigate();
  const id = useParams<UUIDParam<T>>()[ID_PATH_PARAM];

  if (id === undefined)
  {
    navigate(ROUTE_DASHBOARD);

    return "";
  }

  return id;
}

export default function UkloRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<LoginOrRegister />} />
        <Route path="welcome" element={<WelcomePage />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path={`centre/:${ID_PATH_PARAM}`} element={<CentrePage />} />
        <Route path="marking">
          {Object.values(MarkingMode).map(mode => (
            <Route key={mode} path={`${mode}/:${ID_PATH_PARAM}`} element={<MarkingPage mode={mode} />} />
          ))}
        </Route>
        <Route path="admin" element={<AdminPage />} />
        <Route path="assignment" element={<MarkerAssignmentPage />} />
        <Route path="seasons" element={<SeasonsPage />} />
        <Route path={`season/:${ID_PATH_PARAM}`} element={<SeasonManagementPage />} />
        <Route path="*" element={<Dashboard />} />
      </Route>
    </Routes>
  );
}
