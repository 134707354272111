import axios from 'axios';
import Account, { AccountRole } from '../domain/Account';
import account from "../domain/Account";
import { UUID } from "../domain/UUID";

export interface AccountListResponse {
  readonly accounts: Account[]
}

export function getAllAccounts(): Promise<Account[]> {
  return axios.get<AccountListResponse>('/accounts').then(({ data }) => data.accounts);
}

export function getMe(): Promise<Account> {
  return axios.get('/accounts/me').then(({ data }) => data);
}

export function postRegister(name: string, email: string, password: string): Promise<Account> {
  return axios.post<Account>('/accounts/register', { name, email, password }).then(({ data }) => data);
}

export function postLogin(email: string, password: string): Promise<Account> {
  return axios.post<Account>('/accounts/login', { email, password }).then(({ data }) => data);
}

export function postLogout(): Promise<any> {
  return axios.post("/accounts/logout")
}

export function requestRole(role: AccountRole): Promise<void> {
  return axios.patch("/accounts/roles", { role, intent: 'ADD' });
}

export function removeRole(role: AccountRole): Promise<void> {
  return axios.patch("/accounts/roles", { role, intent: 'REMOVE' });
}

export function impersonate(accountId: UUID<Account>): Promise<void> {
  return axios.post(`/accounts/impersonate/${accountId}`);
}
