import * as React from 'react';
import { useState } from 'react';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import { Container, Grid, Link, Paper, Stack } from '@mui/material';
import doNothing from '../common/doNothing';

export default function LoginOrRegister() {
  const [displayLoginForm, setDisplayLoginForm] = useState(true);

  return (
    <Container component="main" maxWidth="xs">
      <Paper sx={{mt: 4, p: 4, pb: 3}}>
        <Stack alignItems="center">
          {displayLoginForm ? <LoginForm /> : <RegisterForm />}
          <Grid container>
            <Grid item xs>
              <Link href="#" onClick={doNothing} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" onClick={() => setDisplayLoginForm(!displayLoginForm)} variant="body2">
                {displayLoginForm ? "Create an account" : "Log in with an existing account"}
              </Link>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
    </Container>
  );
}
