import * as React from "react";
import Page from "../common/Page";
import AccountTable from "./AccountTable";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

export default function AdminPage() {
  return (
    <Page
      title="Admin dashboard"
      subtitle="Work in progress"
      main
    >
      <ul>
        <li>
          <Link component={RouterLink} to={`/seasons`}>
            Manage exam seasons
          </Link>
        </li>
        <li>
          <Link component={RouterLink} to={`/assignment`}>
            Marker assignment
          </Link>
        </li>
      </ul>
      <AccountTable />
    </Page>
  )
}
